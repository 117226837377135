import clsx from 'clsx'
import {
  FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import Button from '@/components/Button'

import { useWindowScroll } from '@/hooks/useWindowScroll'
import { useWindowSize } from '@/hooks/useWindowSize'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import Portal from '../Portal'

import styles from './Tooltip.module.scss'

interface InfoProps extends DefaultComponentProps {
  children: ReactElement
  summary: string
}
const Tooltip: FC<InfoProps> = ({ className = '', children, summary }) => {
  const [isTooltipReady, setTooltipReady] = useState(false)
  const [isTooltipShown, setTooltipShown] = useState(false)
  const [tooltipCSS, setTooltipCSS] = useState({ top: 0, left: 0 })
  const [triggerPosition, setTriggerPosition] = useState({ top: 0, left: 0 })

  const [tooltipRect, setTooltipRect] = useState(null)

  const windowSizeState = useWindowSize()
  const [windowScrollState] = useWindowScroll()

  const { y: windowScrollY } = windowScrollState

  const { width: windowWidth, height: windowHeight } = windowSizeState

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const node = e.target as HTMLElement
      const { top, left, width } = node.getBoundingClientRect()
      setTooltipReady(true)
      setTriggerPosition({ top: top + windowScrollY, left: left + width / 2 })
    },
    [windowScrollY]
  )

  useEffect(() => {
    window.addEventListener('touchstart', handleOutsideClick)

    return () => {
      window.removeEventListener('touchstart', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (!isTooltipReady || !tooltipRect) {
      return
    }
    const { height = 0, width = 0 } = tooltipRect
    const { top: triggerTop, left: triggerLeft } = triggerPosition
    setTooltipCSS(({ top = 0, left = 0 }) => {
      let leftPos = triggerLeft - width / 2
      return {
        top: triggerTop - height - 10,
        left:
          leftPos + width > windowWidth
            ? leftPos - (leftPos + width - windowWidth)
            : leftPos
      }
    })
    setTooltipShown(true)
  }, [tooltipRect, isTooltipReady, windowWidth, windowHeight, triggerPosition])

  const adjustedBoundingRect = (el: HTMLElement) => {
    var rect = el.getBoundingClientRect()
    var style = getComputedStyle(el)
    var tx = style.transform

    if (tx) {
      var sx, sy, dx, dy
      if (tx.startsWith('matrix3d(')) {
        const ta = tx.slice(9, -1).split(/, /)
        sx = +ta[0]
        sy = +ta[5]
        dx = +ta[12]
        dy = +ta[13]
      } else if (tx.startsWith('matrix(')) {
        const ta = tx.slice(7, -1).split(/, /)
        sx = +ta[0]
        sy = +ta[3]
        dx = +ta[4]
        dy = +ta[5]
      } else {
        return rect
      }

      const to = style.transformOrigin
      const x = rect.x - dx - (1 - sx) * parseFloat(to)
      const y =
        rect.y - dy - (1 - sy) * parseFloat(to.slice(to.indexOf(' ') + 1))
      const w = sx ? rect.width / sx : el.offsetWidth
      const h = sy ? rect.height / sy : el.offsetHeight
      return {
        x: x,
        y: y,
        width: w,
        height: h,
        top: y,
        right: x + w,
        bottom: y + h,
        left: x
      }
    } else {
      return rect
    }
  }
  // @ts-ignore
  const handleRect = useCallback(node => {
    // @ts-ignore
    setTooltipRect(node ? adjustedBoundingRect(node) : null)
  }, [])

  const handleOutsideClick = useCallback(() => {
    setTooltipShown(false)
    setTooltipReady(false)
  }, [])

  return (
    <>
      <Button
        variant={'unstyled'}
        type="button"
        className={clsx(styles['tooltip-trigger'], className)}
        onClick={handleClick}
        onMouseEnter={handleClick}
        onMouseLeave={handleOutsideClick}
      >
        {children}
      </Button>
      {isTooltipReady ? (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <Portal>
            <div
              ref={handleRect}
              style={tooltipCSS}
              className={clsx(styles['tooltip'], {
                [styles['tooltip_shown']]: isTooltipShown
              })}
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          </Portal>
        </OutsideClickHandler>
      ) : null}
    </>
  )
}

export default Tooltip
